/*[ PADDING ]
///////////////////////////////////////////////////////////
*/
.p-t-15 {padding-top: 15px;}
.p-t-17 {padding-top: 17px;}
.p-t-22 {padding-top: 22px;}
.p-t-25 {padding-top: 25px;}

.p-b-20 {padding-bottom: 20px;}
.p-b-22 {padding-bottom: 22px;}
.p-b-25 {padding-bottom: 25px;}
.p-b-30 {padding-bottom: 30px;}

.p-l-50 {padding-left: 50px;}

.p-r-50 {padding-right: 50px;}

.m-b-10 {margin-bottom: 10px;}
.m-b-16 {margin-bottom: 16px;}

.m-l-4 {margin-left: 4px;}


/* ------------------------------------ */
.text-up {text-transform: uppercase;}

/* ------------------------------------ */
.text-center {text-align: center;}
.text-left {text-align: left;}
.text-right {text-align: right;}
.text-middle {vertical-align: middle;}


/*[ Width & Height ]
-----------------------------------------------------------
*/

.w-full {width: 100%;}
.h-full {height: 100%;}

/* ------------------------------------ */
.flex-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-direction: row;
	-moz-flex-direction: row;
	-ms-flex-direction: row;
	-o-flex-direction: row;
	flex-direction: row;
}


@keyframes loading-gif {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
.loading-gif 
{
	display: none;
	position: fixed;
	z-index: 1000;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba( 0, 0, 0, 0.5);
}
.loading-gif:after 
{
	top: 50%;
	left: 50%;
	position: fixed;
	content: " ";
	display: block;
	width: 46px;
	height: 46px;
	border-radius: 50%;
	border: 5px solid #fcf;
	border-color: #fcf transparent #fcf transparent;
	animation: loading-gif 1.2s linear infinite;
}
.loading_show{
	display:block;
}