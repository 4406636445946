.health-link {
  max-width: 500px;
  margin: 0 auto;
  width: 100%;
  height: 45px;
  font-size: 16px;
  color: #314d81 !important;
  border-bottom: 1px solid #eee;
  &:hover {
    text-decoration: none;
  }
  i {
    &:first-child {
      font-size: 25px;
      margin-right: 10px;
    }
    &:last-child {
      margin-left: auto;
    }
  }
}

.ecg-card-out {
  border: none !important;
  background: none !important;
}

.ecg-container-box {
  background: white;
  border-radius: 10px;
  margin-bottom: 16px;
  padding: 10px;
  box-shadow: 0px 3px 3px rgba($color: #000000, $alpha: 0.1);
  border: 1px solid #eee;
  .ecg-basic-info {
    justify-content: space-between;
    p {
      font-size: 13px;
      i { color: rgb(254,44,85)}
    }
  }
}