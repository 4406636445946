.home-footer {
  background-color: #C1C8D8;
  padding: 21px 0 37px;
  margin-top: auto;
  img {
    width: 55px;
    height: 55px;
  }
  p {
    margin-bottom: 0;
    font-size: 15px;
  }
  .bottom-texts {
    display: flex;
    .link-area {
      display: flex;
    }
  }
  a {
    color: #273244;
    &:hover {
      text-decoration: none;
    }
  }
}

@media (min-width: 992px) {
  .home-footer {
    img {
      margin-bottom: 20px;
    }
    .bottom-texts {
      flex-direction: row;
      justify-content: space-between;
      .link-area {
        flex-direction: row;
        a {
          margin-left: 30px;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .home-footer {
    img {
      margin-bottom: 15px;
    }
    .bottom-texts {
      flex-direction: column-reverse;
      .link-area {
        flex-direction: column;
        a {
          margin-bottom: 15px;
        }
      }
    }
  }
}