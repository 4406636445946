.home-header {
  background-color: white;
  box-shadow: 0px 2px 6px rgba($color: #000000, $alpha: 0.1);
  display: flex;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 200;
  a {
    &:hover {
      text-decoration: none;
    }
  }

  .menu-btn {
    cursor: pointer;
    margin-left: 70px;
    color: #002363;
    font-size: 18px;
    i {
      font-size: 25px;
    }
    span {
      margin-left: 8px;
    }
  }

  .header-menus {
    margin-left: 70px;
    i {
      cursor: pointer;
      color:  #D4391D;
      font-size: 25px;
    }
    a {
      margin-left: 30px;
      color: #002363;
      font-size: 16px;
    }
  }

  .btn-area {
    .auth-btn {
      width: 110px;
      height: 39px;
      font-size: 15px;
      border-radius: 11px;
      cursor: pointer;
      &.login {
        color: white;
        background-color: #002363;
      }
      &.signup {
        color: #D4391D;
        border: 1px solid #D4391D;
        margin-left: 14px;
      }
    }
  }
}

@media (min-width: 992px) {
  .home-header {
    height: 55px;
    .container {
      justify-content: space-between;
    }
  }
}

@media (max-width: 991px) {
  .home-header {
    height: 44px;
    .container {
      position: relative;
      justify-content: center;
      .mobile-menu-btn {
        font-size: 25px;
        color: #002363;
        position: absolute;
        left: 15px;
      }
    }
  }
}