@keyframes sidebarShow {
  0% {  transform: translateX(-100%); }
  100% { transform: translateX(0); }
}

@keyframes sidebarHide {
  0% { transform: translateX(0); }
  100% { transform: translateX(100%); }
}

.home-sidebar {
  .sidebar-wrapper {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 250;
    background: none;
    height: 100vh;
    font-size: 15px;
    pointer-events: none;
    display: flex;
    .sidebar-block {
      flex: 1;
      height: 100%;
      display: none;
      pointer-events: auto;
    }
    .sidebar-content {
      background: #002363;
      width: 300px;
      margin-left: -100%;
      height: 100%;
      pointer-events: auto;
      padding: 30px 20px 80px 20px;
      .avatar-area {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background-color: white;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          &.logo-inside {
            width: 80%;
            height: 80%;
            border-radius: 0;
          }
        }
      }
      .name-area {
        color: white;
        font-size: 20px;
        padding-left: 15px;
        margin: 0;
      }
      .menu-area {
        flex: 1;
        overflow-y: auto;
        margin-top: 30px;
        background-color: white;
        padding: 10px;
        .auth-btn {
          width: 150px;
          height: 39px;
          font-size: 15px;
          border-radius: 11px;
          cursor: pointer;
          color: white;
          &.login {
            background-color: #002363;
          }
          &.signup {
            background-color: #D4391D;
            margin: 10px 0 30px 0;
          }
        }
        .menu-item {
          color: #002363;
          font-size: 16px;
          margin-bottom: 20px;
        }
        .log-out {
          margin-top: auto;
        }
      }
    }
  }

  &.active {
    .sidebar-block {
        display: block;
    }
    .sidebar-content {
        margin-left: 0;
        animation: sidebarShow 0.2s ease-out;
    }
  }

  &.inactive {
    .sidebar-block {
        display: none;
    }
    .sidebar-content {
        margin-left: -100%;
        animation: sidebarHide 0.2s ease-out;
    }
  }
}