.post_home{
	padding-top: 20px;
	margin-bottom: auto;
	.post-container {
		align-items: center;
		display: flex;
		flex-direction: column;
		width: 100%;
		max-width: 600px;
		margin: 0 auto 150px auto;
		position: relative;
	}
	.input-group-text{
		width: 130px;
	}
	.thumbnail{
		margin-top: 15px;
		img{
			margin:auto;
			max-width: 100%;
		}
	}
	.post_view{
		padding: 20px;
		border: 1px solid #d2d2d2;
		border-radius: 10px;
		margin:auto;
		margin-bottom: 25px;
		background-color: white;
		box-shadow: 0 0.0625rem 0.375rem 0 rgba(0, 0, 0, 0.15);
		cursor: pointer;
		color:black;
		width: 100%;
		text-decoration: none;
	}
	.like_dislike_div{
		margin-top: 15px;
		.dislike_div{
			margin-left:20px;
		}
		button{
			outline: none !important;
			border:none;
			color: gray;
			background-color: white;
			i {
				font-size: 15px;
			}
		}
		.like-btn {
			&.activate {
				color: green;
			}
		}
		.dislike-btn {
			&.activate {
				color: red;
			}
		}
		label {
			margin-bottom: 0;
		}
	}

	.item-content {
		display: -webkit-box;
		-webkit-line-clamp: 3;
		overflow: hidden;
		text-overflow: ellipsis;
		-webkit-box-orient: vertical;
		margin-top: 15px;
		margin-bottom: 0;
		font-size: 13px;
	}

	.search-container {
		margin-bottom: 20px;
		width: 100%;
		max-width: 100%;
		.search-box {
			height: 44px;
			border-radius: 20px;
			border: 1px solid #eeeeee;
			padding: 0 10px 0 20px;
			background-color: white;
			flex: 1;
			i {
				font-size: 17px;
			}
			.search-input {
				flex: 1;
				border: none;
				outline: none;
				width: 100%;
				margin-left: 10px;
			}
		}
	
		.add-btn {
			padding: 0 15px;
			height: 44px;
			margin-left: 15px;
			border-radius: 20px;
			font-size: 15px;
			background-color: white;
			cursor: pointer;
		}
	}
	

	.shadow-box {
    box-shadow: 0px 10px 10px rgba($color: #000000, $alpha: 0.1);
	}
}

.add-modal {
	.modal-content {
		display: flex;
		flex-direction: column;
		padding: 0 !important;
		img {
			max-width: 100%;
			margin-bottom: 15px;
		}
		.disabled {
			opacity: 0.5;
			pointer-events: none;
		}
		p {
			margin: 0;
		}
		.title-part {
			padding: 0 10px;
			height: 30px;
			color: white;
			justify-content: space-between;
			background-color: #314d81;
			.close-btn {
				font-size: 30px;
				cursor: pointer;
			}
		}
		.add-content {
			padding: 10px;
			input {
				padding-left: 5px;
				margin-bottom: 15px;
				&:focus {
					outline: none;
				}
			}
			textarea {
				height: 100px;
				resize: none;
				padding: 5px;
				margin-bottom: 10px;
				&:focus {
					outline: none;
				}
			}
			.btn-area {
				justify-content: space-between;
				label {
					margin: 0;
				}
				i {
					font-size: 20px;
					cursor: pointer;
					color: #314d81;
				}
				.post-btn {
					color: white;
					background-color: #314d81;
					border-radius: 5px;
					cursor: pointer;
					padding: 5px 10px;
				}
			}
		}
	}
}