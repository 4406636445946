.chat_content{
		height: 100%;
    border: 1px solid #dedede;
    border-radius: 3px;
		display: flex;
		flex-direction: row;
		background-color: white;
		box-shadow: 0px 2px 6px rgba($color: #000000, $alpha: 0.1);
		p {
			margin: 0;
		}
		.remain_review{
			display:none;
		}
		.search_contact {
			height: 35px !important;
			box-shadow: 0px 2px 6px rgba($color: #000000, $alpha: 0.1);
			border-radius: 15px !important;
			max-width: 90%;
			margin: 10px auto 0;
		}
		.suggestion {
			padding: 10px;
			a {
				margin: 5px auto !important;
				width: 100px;
				height: 30px;
				background-color: #314d81;
				color: white !important;
				border-radius: 8px;
			}
			h4 {
				font-size: 20px;
				margin: 8px 0;
				&:first-child {
					margin-top: 15px;
				}
			}
			p {
				font-size: 13px;
			}
		}
		input {
			&::placeholder {
				color: #aaaaaa;
			}
			&:focus {
				-webkit-box-shadow: none !important;
				-moz-box-shadow: none !important;
				box-shadow: none !important;
				outline-color: transparent;
  				outline-style: none;
			}
		}
		.feedback_view{
			margin-top:20px;
			margin-bottom:20px;
			flex: 1;
			overflow-y: auto;
			padding: 0 20px;
			.feedback-row{
				padding: 10px;
				border: 1px solid #b8b8b8;
				border-radius: 5px;
				margin: 10px 0 0 0;
			}
			.row1{
				margin-left: 20px;
				display: block;
				text-align: center;
				padding: 10px;
				
				margin-top: 10px;
			}
			textarea{
				width: 95%;
			}
			button{
				margin-top:10px;
				margin-bottom:10px;
			}
			.dv-star-rating{
				margin:auto;
				margin-left:20px;
				font-size:2em
			}
			span{
				margin-left:20px;
			}
			.show_review{
				display: flex;
				.given_patient_detail{
					display:flex;
					label{
						margin-right:10px;
					}
					img {
						width: 50px;
						height: 50px;
						border-radius: 50%;
					}
				}
			}
		}
		
		
		.hide_feedback{
			display:none;
		}
		.contact_type_none{
			display:none !important;
		}
		.contact_type{
			display: flex;
			height: 55px;
			display: flex;
			align-items: center;
			justify-content: center;
			button{
				background-color:#314d81;
				box-shadow: none;
				color:white;
				border-color:#314d81;
			}
			.contact_focus{
				background-color:#d4391d;
				box-shadow: none;
				border-color:#d4391d;
			}
			.contact_chat{
				margin-right:20px;
			}
		}
		.request-area{
			text-align:center;
			.request_chat_btn{
				margin-top:3%;
			}
		}
		.contact-menu {
			
			width: 240px;
			border-right: 1px solid #dedede;
			display: flex;
			flex-direction: column;
			input {
				//border: none;
				border-bottom: 1px solid #dedede;
				border-radius: 0;
				outline: none;
				height: 50px;
				&:focus {
					outline-color: transparent;
  				outline-style: none;
				}
			}
			
			.contacts, .contacts_doctor {
				padding: 0 10px;
				flex: 1;
				overflow-y: auto;
				.chat-item {
					margin: 0 -10px;
					padding: 10px;
					display: flex;
					align-items: center;
					cursor: pointer;
					&:hover, &.selected {
						background-color: #ededed;
					}
					img {
						width: 50px;
						height: 50px;
						border-radius: 50%;
						margin-right: 8px;
					}
					.information {
						flex: 1;
						max-width: calc(100% - 58px);
					}
					.last-message {
						font-size: 11px;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
						color: #aaaaaa;
					}
				}
			}
		}
		.chat-area {
			flex: 1;
			display: flex;
			flex-direction: column;
			.chat-opponent {
				height: 55px;
				border-bottom: 1px solid #ededed;
				padding: 10px;
				display: flex;
				align-items: center;
				margin-bottom: 8px;
				.btn-close-chat{
					margin-left:auto;
				}
				img {
					width: 35px;
					height: 35px;
					border-radius: 50%;
					margin-right: 10px;
				}
			}
			.messages-area {
				flex: 1;
				display: flex;
				flex-direction: column-reverse;
				padding: 10px;
				overflow-y: auto;
				max-height: calc(100% - 90px);
				.message-item {
					margin-top: 11px;
					width: 100%;
					max-width: 70%;
					display: flex;
					min-height: fit-content;
					img {
						width: 50px;
						height: 50px;
						margin-right: 10px;
						border-radius: 50%;
					}
					.message-detail {
						display: flex;
						flex-direction: column;
						p {
							font-size: 11px;
							margin-bottom: 5px;
							color: #888888;
						}
						.message-content {
							background: #4364a1;
							width: 100%;
							color: white;
							font-size: 13px;
							padding: 10px;
							border-radius: 10px;
							border-top-left-radius: 0;
							word-break: break-word;
						}
					}
					&.mine {
						margin-left: auto;
						.message-detail {
							margin-left: auto;
							p {
								text-align: right;
							}
							.message-content {
								background: #e9695c;
								border-top-left-radius: 10px;
								border-bottom-right-radius: 0;
							}
						}
					}
				}
			}
			.input_hidden{
				display:none !important;
			}
			.message-input {
				height: 40px;
				display: flex;
				align-items: center;
				border-top: 1px solid #ededed;
				
				.message {
					height: 100%;
					border: none;
					padding: 0 10px;
				}
				button {
					width: 35px;
					height: 35px;
					border: none;
					outline: none;
					background: white;
					color: black;
				}
			}
		}
}

@media (min-width:992px) {
	.chat_content{
		.contact-menu {
			width: 240px;
		}
		.chat-area {
			.return-arrow {
				display: none;
			}
		}
	}
}

@media (max-width:991px) {

	.chat_content{
		margin-top:24px;
		.contact-menu {
			width: 100%;
			&.chat_selected {
				display: none;
			}
		}
		.chat-area {
			.return-arrow {
				display: block;
				cursor: pointer;
				font-size: 25px;
				margin-right: 10px;
			}
		}
	}
}