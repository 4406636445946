.profile_app{
    .input-group-text{
        width: 130px;
    }
    .profile-user-img{
				width: 145px;
				height: 145px;
				border-radius: 50%;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
      }
      
      .avatar-upload {
          position: relative;
          max-width: 205px;
          margin: auto;
          margin-bottom: 20px;
        
          .avatar-edit {
              position: absolute;
              right: 40px;
              z-index: 1;
              top: 90px;
              input {
                  display: none;
                  + label {
                      width: 34px;
                      height: 34px;
                      margin-bottom: 0;
                      border-radius: 100%;
                      background: #FFFFFF;
                      border: 1px solid #d2d6de;
                      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
                      cursor: pointer;           
                      font-weight: normal;
                      transition: all .2s ease-in-out;
                      &:hover {
                          background: #f1f1f1;
                          border-color: #d6d6d6;
                      }
                      i {
                        color: #337AB7;
                        line-height: 34px;
                      }
                  }
              }
          }
      }
}