.check-form {
	.StripeElement {
		height: 60px;
		padding: 5px 0;
		display: flex;
		align-items: center;
		padding: 0 10px 0 20px;
		border-radius: 10px;
		background-color: rgba($color: #32325d, $alpha: 0.1);
		.__PrivateStripeElement {
				flex: 1;
		}
	}
	.card-error {
		color: #d34736;
	}

	.spinner,
	.spinner:before,
	.spinner:after {
		border-radius: 50%;
	}
	.spinner {
		color: #ffffff;
		font-size: 22px;
		text-indent: -99999px;
		margin: 0px auto;
		position: relative;
		width: 20px;
		height: 20px;
		box-shadow: inset 0 0 0 2px;
		-webkit-transform: translateZ(0);
		-ms-transform: translateZ(0);
		transform: translateZ(0);
	}

	.save-btn {
		margin-top: 20px;
		width: 100%;
	}
}
