.post_detail_home{
	padding-top: 20px;
    padding-bottom: 20px;
    .post-home-container-box {
        background-color: white;
        box-shadow: 0px 10px 10px rgba($color: #000000, $alpha: 0.1);
        padding: 15px;
    }
    .like_dislike_div{
        .dislike_div{
            margin-left:20px;
        }
        button{
            outline: none !important;
            border:none;
            color: gray;
            background-color: white;
            i {
                font-size: 15px;
            }
        }
        .like-btn {
            &.activate {
                color: green;
            }
        }
        .dislike-btn {
            &.activate {
                color: red;
            }
        }
        label {
            margin-bottom: 0;
        }
    }
    .avatar-preview{
        max-width: 100%;
        border-radius:10px;
        margin-bottom:20px;
        img {
            max-width: 100%;
        }
    }
    .comment-input {
        border:1px solid gray;
        height: 40px;
        display: flex;
        align-items: center;
        border-radius:10px;
        background-color: white;
        padding: 0 5px 0 10px;
        input {
            height: 100%;
            border: none;
            // padding: 0 10px;
            width: 100%;
            font-size: 13px;
        }
        input:focus{
            outline: none !important;
        }

        button {
            width: 35px;
            height: 35px;
            border: none;
            outline: none;
            background: white;
            color: black;
        }
    }
    .comment_div{
        border: 1px solid #d0c7c7;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0px 10px 10px rgba($color: #000000, $alpha: 0.1);
    }
    .show_comment{
        display: flex;
        
        label{
          margin-left:5px;
        }
        .given_user_detail{
            display:flex;  
        }
    }
}